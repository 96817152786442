/* Additional settings */
.mar-b10 {
    margin-bottom: 10px;
}

.table-center th {
    text-align: center;
}

.table-center td {
    text-align: center;
}

.pad-left-0 {
    padding-left: 0;
}

.float-r {
    float: right;
}

.hidden {
    display: none;
}

.jersey-input {
    width: 50px;
    margin: auto;

    text-align: center;
}

@media print {
    @page {
        size: A4 landscape;

        margin: 5mm 7mm;
        print-color-adjust: exact;
    }
}

.control-action-container {
    bottom: 50px;

    height: 4em;
}
@media screen and (max-width: 1024px) {
    .control-action-container {
        position: absolute;
        bottom: 0px;

        height: 5em;
    }
}
@media screen and (max-width: 390px) {
    .control-action-container {
        position: absolute;
        bottom: -30px;

        height: 5em;
    }
}
