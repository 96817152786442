.header {
    display: grid;

    grid-template-columns: repeat(4, 25%);
    grid-template-rows: 1rem 8rem 1rem;

    padding: 0 0.5rem;

    user-select: none;
}

.singleGameHeader {
    display: flex;
    justify-content: center;
}

.singleGameHeaderColumn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-weight: bold;
}

.singleGameLogo {
    width: 54px;
    height: 54px;
    margin-top: 0;
}

.headerItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerSubitem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.singleHeaderSubitem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-weight: 500;
}

.headerColumnLeft {
    grid-column: 1;
    grid-row: 2;

    color: #3a66ab;
}

.headerColumnRight {
    grid-column: 4;
    grid-row: 2;

    color: #c55267;
}

.headerRightTeamName {
    color: #c55267;
}

.headerColumnCenter {
    display: flex;
    flex-direction: column;

    grid-column-end: 4;
    grid-column-start: 2;
    grid-row: 1;

    align-items: center;

    width: 100%;

    font-family: Roboto, sans-serif;
}

.logo {
    width: 64px;
    height: 64px;
    margin-top: 15px;
}

.teamName {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 1rem;

    font-weight: 700;
    font-size: 14px;
    line-height: 1.3em;
    text-align: center;
}

:global(.darcula) .teamName {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
}

.infoName {
    color: #a6b1c4;
    font-size: 12px;
}

:global(.hockeyTech) .infoName {
    color: var(--darkGrayColor);
}

.infoData {
    color: #a6b1c4;
    font-size: 12px;
    line-height: 1.33;
}

:global(.hockeyTech) .infoData {
    color: var(--mediumGrayColor);
}

.dataName {
    color: #898989;
}

.score {
    display: flex;
    padding: 5px 13px 0;

    color: var(--uiPrimaryColorActive);
    font-weight: bold;
    font-size: 66px;
    line-height: 75px;
}

:global(.hockeyTech) .score {
    color: var(--darkGrayColor);
}

.singleDigitScore {
    font-size: 65px;
}

.doubleDigitScore {
    font-size: 36px;
}

.tripleDigitScore {
    font-size: 25px;
}

.quadroDigitScore {
    font-size: 19px;
}

:global(.darcula) .score {
    color: var(--uiPrimaryColor);
}

.periodScoreContainer {
    display: flex;
    justify-content: space-between;
    width: 110px;
}

.periodScore {
    display: block;

    color: var(--uiPrimaryColorDark);
    font-weight: 400;
}

:global(.hockeyTech) .periodScore {
    color: var(--darkGrayColor);
}

.for {
    color: var(--forColor);
}

.against {
    color: var(--againstColor);
}

.supLeft {
    align-self: flex-start;

    font-size: 15px;
    line-height: 15px;
}

.supRight {
    align-self: flex-start;

    font-size: 15px;
    line-height: 15px;
}

@media screen and (max-width: 550px) {
    .header {
        grid-template-rows: 2em 4rem 12rem 1rem;
    }

    .headerColumnLeft {
        grid-row: 2;
        padding-left: 1em;
    }

    .headerColumnRight {
        grid-row: 2;
        padding-right: 1em;
    }
    .headerColumnCenter {
        grid-row: 3;
    }
    .logo {
        width: 3.5rem;
        height: 3.5rem;
        margin-top: 0;
    }
    .teamName {
        font-size: 10px;
    }
    .score {
        line-height: 2em;
    }
    .periodScoreContainer {
        justify-content: space-around;
        width: 200%;
    }
    .supLeft,
    .supRight {
        margin-top: 2em;
    }
}
