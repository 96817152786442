.table {
    width: 100%;
    max-width: 100%;

    overflow: auto;

    border-collapse: collapse;
    border-spacing: 0;

    color: var(--defaultColor);
}

:global(.hockeyTech) .table {
    color: var(--mediumGrayColor);
}

:global(.darcula) .table {
    color: rgba(255, 255, 255, 0.7);
}

.table th {
    /*padding: 0.25rem;*/
    padding: 4px;

    font-size: 14px;
    vertical-align: middle;
}

@media print {
    .table td {
        padding: 1px;
    }
}

:global(.darcula) .table th {
    color: rgba(255, 255, 255, 0.6);
}

.verticalCenter {
    vertical-align: middle !important;
}

.table td {
    padding: 0.25rem;

    font-size: 14px;
    /*padding: 4px;*/
    cursor: default;
}

@media print {
    .table td {
        padding: 1px;
    }
}

.table th.important {
    color: #707c93;
    font-weight: 600;
    /*font-size: 1rem;*/
    font-size: 12px;
}

.table td.important {
    color: #707c93;
    /*font-size: 12px;*/
    font-weight: 600;
    font-size: 1rem;
}

:global(.darcula) .table td.important {
    color: rgba(255, 255, 255, 0.7);
}

.table thead th,
.table tfoot th {
    font-weight: normal;
    /*font-size: 0.75rem;*/
    font-size: 12px;
    vertical-align: middle;
}

@media print {
    .table thead th {
        color: white !important;

        background-color: #1f3257 !important;
        -webkit-print-color-adjust: exact;
    }
}

thead {
    border-bottom: 1px solid #f1f1f1;

    color: rgba(0, 0, 0, 0.9);
}
tfoot {
    border-top: 1px solid #f1f1f1;

    color: rgba(0, 0, 0, 0.9);
}

:global(.darcula) thead {
    border-bottom: 1px solid rgba(241, 241, 241, 0.3);
}

:global(.darcula) tfoot {
    border-top: 1px solid rgba(241, 241, 241, 0.3);
}

.th,
.externalTh {
    cursor: pointer;

    user-select: none;
}

.externalTh {
    display: inline-block;

    color: #a6b1c4;
    font-weight: normal;
    font-size: 12px;
}

.table tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.table tbody tr:nth-of-type(odd) {
    background-color: #f6f8fb;
}

:global(.hockeyTech) .table tbody tr:nth-of-type(odd) {
    background-color: var(--transparentGreenColor1);
}

:global(.darcula) .table tbody tr:nth-of-type(odd) {
    background-color: #29363e;
}

.align-center {
    font-family: Roboto, sans-serif;
    text-align: center;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.default-column {
    max-width: 45px;

    text-align: right;
}

.table tbody tr.hovered:not(.disabled) {
    color: #707c93;

    background: rgba(74, 144, 226, 0.15);
}

.table tbody tr:hover:not(.disabled) {
    color: #707c93;

    background: rgba(74, 144, 226, 0.15);
}

.table tbody tr.selected {
    color: rgba(0, 0, 0, 0.9);
}

:global(.darcula) .table tbody tr:hover {
    color: rgba(255, 255, 255, 0.9);

    background: rgba(41, 103, 176, 0.5);
}

:global(.hockeyTech) .table tbody tr:hover {
    background: var(--transparentGreenColor2);
}

.table tbody tr {
    cursor: pointer;
}

.table tbody tr.selected {
    color: #707c93;

    background: rgba(74, 144, 226, 0.3) !important;
}

:global(.hockeyTech) .table tbody tr.selected {
    color: var(--darkGrayColor);

    background: var(--transparentGreenColor3) !important;
}

:global(.darcula) .table tbody tr.selected {
    color: rgba(255, 255, 255, 0.9);

    background: rgba(41, 103, 176, 1);
}

.fixedWidth {
    max-width: 90px;
}

.dummyColumn {
    width: 20px;
}

.csv {
    margin-right: 20px;

    font-weight: 400;
    text-decoration: underline;

    cursor: pointer;
}

.csv {
    text-decoration: none;
}

@media (max-width: 1400px) {
    .table thead th,
    .table tfoot th,
    .externalTh {
        font-size: 10px;
    }
}

@media print {
    .table td.important {
        font-size: 14px;
    }
}

@media screen and (max-width: 550px) {
    .table {
        display: block;
    }
}
